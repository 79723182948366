@tailwind base;
@tailwind components;
@tailwind utilities;

body {

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Digital-background {
  background: linear-gradient(138deg,
      rgba(193, 191, 191, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(193, 191, 191, 1) 100%);
}

.slick-arrow {
  display: none !important;
}

.virtualBackground {
  background: linear-gradient(138deg,
      rgba(217, 252, 249, 1) 0%,
      rgba(255, 255, 255, 1) 76%);

  border-radius: 0px 180.5px 180.5px 0px;
  padding-top: 100px;
}

.SignatureBackground {
  background: linear-gradient(269.32deg,
      rgba(14, 119, 211, 0.1) 0.54%,
      rgba(14, 119, 211, 0) 99.41%);
  border-radius: 180.5px 0px 0px 180.5px;
}

.TeamsBackground {
  background: linear-gradient(269deg, rgba(233, 245, 255, 0.00) 0.54%, #F6FBFF 99.41%);
  border-radius: 0px 180.5px 180.5px 0px;
}

.gsap-marker-scroller-start {
  display: none !important;
}

.w-max-li {
  width: max-content !important;
}

.gsap-marker-scroller-end {
  display: none !important;
}

.gsap-marker-start {
  display: none !important;
}

.gsap-marker-end {
  display: none !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.homeSlide-side {
  position: relative;
  overflow: hidden;
}

.homeSlide-side .overflow-hidden {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.5s ease;
  /* Adjust timing and easing as needed */
}

.homeSlide-side .overflow-hidden>* {
  flex: 0 0 100%;
}

.homeSlide-side .homeSlider-arrow-button {
  background: transparent;
  cursor: pointer;
  outline: none;
}

/* Adjust positioning for the arrows if needed */
.homeSlide-side .homeSlider-arrow-button span {
  color: #fff;
  font-size: 24px;
}

.carousel-wrapper {
  display: flex;
  gap: 2rem;
  transition: transform 0.5s ease-in-out;
}

.carousel-wrapper p {
  width: 90% !important;
}

.carousel-wrapper a {
  height: 3rem !important;
}

.carousel-control-next button {
  background-color: #fff !important;
}

.carousel-control-prev button {
  background-color: #fff !important;
}

.carousel-slide {
  min-width: 100%;
  /* margin: 0 2rem; Adjust padding as needed */
}

.homeSlider-arrow-button {
  border: 1px solid #fff !important;
  padding: 12.6px;
  border-radius: 50% !important;
}

.homeSlider-arrow-button:hover {
  .nwbtn {
    color: #000;
  }
}

.homeSlider-arrow {
  border: 1px solid #0F77D2 !important;
  padding: 17px;
  border-radius: 50% !important;
}

.homeSlider-arrow:hover {
  .nwbtn {
    color: #fff;
  }
}

@layer utilities {
  .rotate {
    animation: spin infinite 3s linear;
  }
}

.mainContainer{
  width: 100%;
  display: table;
  position: relative;    box-sizing: border-box;
}

.mNavIcon {
  display: none;
}
.elve{
  font-size: 48px;
  font-weight: normal;
  line-height: 64px;
}
.prof{
    font-weight: 600;
    display: inline-block;
    width: 100%;
}
.fz14{
  font-size: 14px;
}
.h110vh{
height: 110vh;
}
.h80vh{
  height: 60vh;
}
.whyUs{
  font-size: 40px;
}
.whyUsImg{
  height: 304px;
}
@media screen and (max-width: 1024px) {
  .mHide{
    display: none;
  }
  .mNavIcon {
    display: inherit;
    float: right;
  }

  .headerNav {
    position: relative;
    display: table;
    box-sizing: border-box;
    width: 100%;padding: 10px 10px;
  }

  .navList {
    display: none;

  }
  .navList .text-base{
    margin: 10px 0px;
  }
  .cursor-pointer{
    float: left;
  }
  .headerNav:hover .navList {
    position: absolute;
      display: table;
      width: 100%;
      background: white;
      left: 0;
      top: 74px;
      box-sizing: border-box;
      padding: 20px;
      padding-top: 0px;
z-index: 9;
  }
  .cntButton{
    max-width: 320px;
  }
.elve{
  font-size: 28px;
  line-height: 36px;
}
.mCenterText{
  text-align: center;
}
.p-\[100px\]{
  padding: 20px;
}
.whyUs{
  font-size: 30px;
  line-height: 32px;
  padding: 10px 0px 10px 0px;
}
.mobMg-30{
  margin-top: 30px;
}
.whyUsImg{
  height: auto;
  width: 100%;
}
.text-\[40px\]{
  font-size: 30px;
  text-align: center;
  line-height: 2.4rem;
}
.w-\[70\%\]{
  width: 100%;
}
.text-base{
  font-size: 0.9rem;
  line-height: 1.3rem;
}
.gallery{
  width: 100%;
    gap: 0px;

}
.ps-\[180px\]{
  padding-inline-start:10px;
}
.css-hksm8y {
  width: 100% !important;
}
.text-\[64px\] {
  font-size: 36px;
}
.leading-\[83px\] {
  line-height: 58px;
}
.css-k008qs{
  width: 100%;
}
.rightblock{
  display: none !important;
}
#OurFeatures{
  position: inherit;
}
/* .mo-pd-20{
  padding: 20px;
} */
.text-4xl {
  font-size: 1.75rem;
  line-height: 2.5rem;
}
.css-hksm8y .details{
  height: 50vh !important;
}
.text-\[24px\] {
  font-size: 18px;
}
.ps-\[150px\] {
  padding-inline-start: 5px;
}
.moTextCenter{
  text-align: center;
}
.w-3\/4 {
  width: 100%;
}
#Signature .relative, #Signature .absolute {
  position: inherit;
}
#Signature .flex{
  display: block;
}
.ps-\[120px\] {
  padding-inline-start: 0px;
}
.h-\[60vh\] {
  height: auto;
}
.moDis{
  display: table;
}
.ps-\[5rem\] {
  padding-inline-start: inherit;
}
.footerLink{
  width: 100%;
  display: table;
}
.footerLink span{
  margin-top: 20px;
  display: inline-block;
  text-align: center;
  width: 100%;
}
.footerLink div img{
  margin: 0 auto;
}
.px-\[150px\] {
  padding-left: 0px;
  padding-right: 0px;
}
.py-\[100px\] {
  padding-top: 50px;
  padding-bottom: 50px;
}
}
 


